import { createContext, useState } from "react";

const MapContext = createContext({
	interactions: {},
	setDetail: () => Function,
	setCurrent: () => Function,
});

MapContext.displayName = "MapContext";

export default MapContext;
export const MapProvider = MapContext.Provider;
export const MapConsumer = MapContext.Consumer;
