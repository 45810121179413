import React from "react";
import PropTypes from "prop-types";

import Element from "../Element";

export default function Group({ items }) {
	return items.map((item, idx) => <Element key={`element-map-${idx}`} {...item}/>);
}

Group.propTypes = {
	items: PropTypes.array,
};

Group.defaultProps = {
	items: [],
};
