import React, { createElement, forwardRef, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import { get } from "lodash";

import Group from "../Group";

import MapContext from "./../Context";
import { toJSS } from "../../../helpers";

const Element = forwardRef(({ actions, name, params, attrs, childs, initial }, ref) => {
	const { setDetail, setModal, setIsModalOpen, depth } = useContext(MapContext);

	const element = initial ? ref : useRef(null);

	if ({}.propertyIsEnumerable.call(attrs, "style") && !(attrs.style instanceof Object)) {
		attrs.style = toJSS(attrs.style);
	}

	function openDetail(event, id) {
		setDetail(id);
	}

	function openDialog(event, data) {
		setModal(data);
		setIsModalOpen(true);
	}

	function makeHandlers(actions = {}) {
		const handlerMap = {
			openDialog,
			openDetail,
			default(id = "Without id") {
				return function log() {
					console.warn(`Handler ${id} not found`);
				};
			},
		};
		const handlers = {};

		for (let [key, actionValue] of Object.entries(actions)) {
			handlers[key] = event => {
				for (let [key, value] of Object.entries(actionValue)) {
					(handlerMap[key] || handlerMap.default(key))(event, value);
				}
			};
		}

		return handlers;
	}

	useEffect(() => {
		if (isMobile) {
			const magnifers = document.querySelectorAll("[data-name=magnifer]");
			magnifers.forEach(item => item.style.opacity = 1);
		}
	}, []);

	const depthForVisible = get(params, "depthForVisible", -Infinity);

	return name && +depthForVisible < depth ? createElement(
		name,
		{
			key: `created-element-${name}`,
			ref: element,
			...attrs,
			...makeHandlers(actions),
		},
		<Group items={childs} />,
	) : null;
});

Element.displayName = "Element";

export default Element;

Element.propTypes = {
	actions: PropTypes.object,
	name: PropTypes.string,
	depth: PropTypes.number,
	attrs: PropTypes.object,
	params: PropTypes.object,
	childs: PropTypes.array,
	initial: PropTypes.bool,
};

Element.defaultProps = {
	actions: {},
	name: "",
	depth: 0,
	attrs: {},
	childs: [],
	initial: false,
};
