import React, { useContext, useEffect } from "react";
import { Link } from "gatsby";
import { createPortal } from "react-dom";
import cx from "classnames";
import { get } from "lodash";
import { useDebounceFn, useToggle } from "@umijs/hooks";

import MapContext from "./../Context";

import "./Modal.scss";
import { Image } from "../../Media/Image";

export default function Modal() {
	const portal = document.getElementById("map-detail");

	const { modal, setIsModalOpen } = useContext(MapContext);
	const { state: closed, toggle: setClosed } = useToggle(true);

	useDebounceFn(
		() => {
			setIsModalOpen(!closed);
		},
		[closed],
		300,
	);

	function toggleOverflowDocument(overflow = "initial") {
		const html = document.documentElement;

		if (html) {
			html.style.overflow = overflow;
		}
	}

	useEffect(() => {
		setClosed(false);

		toggleOverflowDocument("hidden");

		return () => toggleOverflowDocument();
	}, []);

	const text = get(modal, "text", "");
	const image = get(modal, "image.src", "");
	const alt = get(modal, "image.alt", "");
	const title = get(modal, "title", "");
	const src = get(modal, "src", "");

	return (
		createPortal(
			<div
				className={cx("interactive-modal", { "interactive-modal_opened": !closed }, { "interactive-modal_closed": closed })}
				onClick={() => setClosed(true)}
			>
				<div
					className={"container"}
					style={{
						height: "100%",
						overflow: "initial",
					}}
				>
					<div
						className={"row"}
						style={{
							height: "100%",
						}}
					>
						<div
							className={"col d-flex align-items-center"}
							style={{
								height: "100%",
							}}
						>
							<div
								className={
									cx(
										"row align-items-center interactive-modal__body",
										{ "interactive-modal__body_opened": !closed },
										{ "interactive-modal__body_closed": closed },
									)
								}
								onClick={event => event.stopPropagation()}
							>
								{title &&
									<div className="d-none d-lg-block interactive-modal__title h5">
										{title}
									</div>
								}
								{title &&
									<h4 className="d-block col-12  d-lg-none h4">
										{title}
									</h4>
								}
								<div className={"interactive-modal__close"} onClick={() => setClosed(true)}>
									<div className={"interactive-modal__inner-close"} />
								</div>
								<div className="p-0 col-12 col-md-4 interactive-modal__img">
									<Image src={image} alt={alt} minHeight={"200px"}/>
								</div>

								<div className="py-3 px-md-5 col-12 col-md-8 interactive-modal__text">
									{text && (
										<div>
											{text}
										</div>
									)}
									{src && (
										<Link to={src} className={"mt-3 d-block h5"}>
												Подробнее
										</Link>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>,
			portal,
		)
	);
}
