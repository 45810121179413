import React, { useEffect, useState, useContext, useRef } from "react";
import { createPortal } from "react-dom";
import { useDebounceFn, useToggle } from "@umijs/hooks";
import cx from "classnames";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";

import Element from "../Element";
import MapContext from "./../Context";
import { Icon } from "../../Media";
import closeMagnifer from "./magnifer-close";

import "./Detail.scss";

const cityList = [
	{
		title: "Свято-Троицкий Серафимо-Дивеевский женский монастырь",
		mobileTitle: "Дивеевский женский монастырь",
		target: "diveevskii",
		actions: {
			id: "diveevskii",
			src: "/diveevo/diveevskiy",
			image: {
				src: "https://storage.yandexcloud.net/serafim-uploads/content/2020/03/04/IMG_5820-HDR_6237d8e0-c5da-4124-9974-6730c963c9dc.jpg",
				alt: "Свято-Троицкий Серафимо-Дивеевский женский монастырь",
			},
			title: "Свято-Троицкий Серафимо-Дивеевский женский монастырь",
			text: "Первоначальница Дивеевской обители преподобная Александра (Мельгунова) просила отца Серафима позаботиться об основанной ею общине. По личному повелению Самой Божией Матери он основал в Дивееве еще одну общину – девичью, Мельничную. В явлении старцу в 1825 году Царица Небесная повелела взять из первоначальной общины восемь сестер, благословила построить для них два Рождественских храма, мельницу и оградить новую общину канавой. Пресвятая Богородица обещала быть игуменией в этой обители и дала для нее новый устав, нигде до того не существовавший.",
		},
	},
	{
		title: "Ближняя пустынька",
		mobileTitle: "Ближняя пустынька",
		target: "blij-pustinka",
		actions: {
			id: "blij-pustinka",
			src: "/pages/blizhnyaya-pustynka",
			image: {
				src: "https://storage.yandexcloud.net/serafim-uploads/2020/06/30/content2.2_834992b8-d3f9-43ab-979f-796c67631706.jpg",
				alt: "Ближняя пустынька",
			},
			title: "Ближняя пустынька",
			text: "В 1825 году преподобный Серафим Саровский по новому явлению ему Божией Матери оставил затвор и принял на себя высший подвиг христианской любви к ближнему – старчество. По выходе из затвора батюшка Серафим делил свое время между Саровским монастырем и новой лесной кельей, так называемой «Ближней пустынькой». Пустынька находилась в двух верстах от обители, на высоком правом берегу реки Саровки. Речка располагалась неподалеку от Богословского источника, впоследствии переименованного в источник преподобного Серафима Саровского.",
		},
	},
	{
		title: "Дальняя пустынька",
		mobileTitle: "Дальняя пустынька",
		target: "daln-pustinka",
		actions: {
			id: "daln-pustinka",
			src: "/pages/dalnyaya-pustynka",
			image: {
				src: "https://storage.yandexcloud.net/serafim-uploads/2020/06/30/content3.2_ce0c278a-da0f-4584-afaa-8a14606eb04b.jpg",
				alt: "Дальняя пустынька",
			},
			title: "Дальняя пустынька",
			text: "Уход отца Серафима на Дальнюю пустыньку, видимо, был связан со стремлением старца к уединению и самоиспытанию души по примеру святых отцов-отшельников. В возрасте сорока лет по благословению своего духовного наставника и строителя пустыни отца Пахомия 27 августа 1794 года батюшка Серафим ушел на жительство в лесную пустынь, в пяти верстах от монастыря на его территории. Располагалась келья старца на правом берегу реки Саровки.",
		},
	},
	{
		title: "Святая Канавка Царицы Небесной",
		mobileTitle: "Святая Канавка",
		target: "_kanavka",
		actions: {
			id: "_kanavka",
			src: "/pages/svyataya-kanavka",
			image: {
				src: "https://storage.yandexcloud.net/serafim-uploads/content/2020/12/03/Photo_svyatayakanavka_bfc80a2a-df4e-4140-b159-6bff9991b61b.jpg",
				alt: "Святая Канавка Царицы Небесной",
			},
			title: "Святая Канавка Царицы Небесной",
			text: "Святая Канавка является одной из самых главных святынь Серафимо-Дивеевского монастыря. Она прокопана по пути, которым прошла Царица Небесная, взяв в Свой удел Дивеевскую обитель. Преподобный говорил, что святую Канавку Сама Царица Небесная Своим пояском измерила и что Канавка до небес высока. А еще отмечал, что кто полтораста «Богородиц» на Канавке прочтет, тот получит благословение и благодать Афона, Иерусалима и Киева.",
		},
	},
	{
		title: "Свято-Успенский мужской монастырь - Саровская пустынь",
		mobileTitle: "Саровская пустынь",
		target: "sarovskaya-pustin",
		actions: {
			id: "sarovskaya-pustin",
			src: "/sarov/svyato-uspenskii-muzhskoi-monastir---sarovskaya-pustin",
			image: {
				src: "https://storage.yandexcloud.net/serafim-uploads/2020/06/30/content5.2_718a08b1-0e1e-4e62-baf4-42505116b1e5.jpg",
				alt: "Свято-Успенский мужской монастырь - Саровская пустынь",
			},
			title: "Свято-Успенский мужской монастырь - Саровская пустынь",
			text: "Саровская пустынь известна как место, где с 1778 по 1833 годы подвизался преподобный Серафим Саровский.",
		},
	},
	{
		title: "Молитвенный камень",
		mobileTitle: "Молитвенный камень",
		target: "kamen",
		actions: {
			id: "kamen",
			src: "/pages/kamen-prepodobnogo-serafima-sarovskogo",
			image: {
				src: "https://storage.yandexcloud.net/serafim-uploads/2020/06/30/content6.2_79e05492-a38c-4fce-8147-dc07e0ec6049.jpg",
				alt: "Молитвенный камень",
			},
			title: "Молитвенный камень",
			text: "На расстоянии нескольких сотен метров от Серафимовского источника располагался камень, на котором батюшка Серафим, согласно преданию, молился 1000 дней и ночей подряд, постоянно живя на Дальней пустыньке. Во время молитвы старец ставил перед собой на росшее рядом дерево икону Святой Троицы. Изображение преподобного Серафима, молящегося на камне перед иконой, – одно из самых распространенных канонических изображений святого.",
		},
	},
];

function CityList({ items, callback, mouseLeave, openModal }) {
	return (
		<div
			className={"interactive-detail__list pb-5"}
		>
			<h3>Серафимова земля</h3>
			{
				items.map(({ title, mobileTitle, target, actions }, idx) => (
					<div key={`interactive-detail-item-${idx}`} className={"mb-3"}>
						<div
							onMouseOver={e => callback(target, e.currentTarget)}
							onMouseLeave={e => mouseLeave(target, e.currentTarget)}
							onClick={() => openModal(actions)}
							className={"interactive-detail__item d-flex"}
						>
							<Icon id={"location"} />
							<span className={"interactive-detail__item-title ml-3"}>
								{isMobile ? mobileTitle : title}
							</span>
						</div>
					</div>
				))
			}
		</div>
	);
}

CityList.propTypes = {
	items: PropTypes.array,
	callback: PropTypes.func,
	mouseLeave: PropTypes.func,
	openModal: PropTypes.func,
};

CityList.defaultProps = {
	items: [],
	callback: () => Function,
};

export default function Detail({ data }) {
	const portal = document.getElementById("map-detail");
	const detailModal = useRef(null);
	const { setDetail, setModal, setIsModalOpen } = useContext(MapContext);
	const { state: closed, toggle: setClosed } = useToggle(true);
	const [isDisplay, setIsDisplay] = useState(true);
	const [opacity, setOpacity] = useState(1);

	function toggleOverflowDocument(overflow = "initial") {
		const html = document.documentElement;

		if (html) {
			html.style.overflow = overflow;
		}
	}

	function selectBuilding(target, currentTarget) {
		setIsDisplay(false);
		setOpacity(0.5);

		if (typeof window !== "undefined") {
			const building = document.getElementById(target);
			building.classList.add("interactive-detail_visible");
			currentTarget.classList.add("interactive-detail_visible");
		}
	}

	function mouseLeave(target, currentTarget) {
		setIsDisplay(true);
		setOpacity(1);

		if (typeof window !== "undefined") {
			const building = document.getElementById(target);
			building.classList.remove("interactive-detail_visible");
			currentTarget.classList.remove("interactive-detail_visible");
		}
	}

	function openModal(data) {
		setModal(data);
		setIsModalOpen(true);
	}

	useDebounceFn(
		() => {
			setDetail(!closed);
		},
		[closed],
		300,
	);

	useEffect(() => {
		const markers = document.getElementById("markers");

		if (!isDisplay) {
			markers.classList.add("interactive-detail_invisible");
		} else {
			markers.classList.remove("interactive-detail_invisible");
			markers.classList.add("interactive-detail_visible");
		}
	}, [isDisplay]);

	useEffect(() => {
		setClosed(false);

		toggleOverflowDocument("hidden");

		return () => toggleOverflowDocument();
	}, []);

	useEffect(() => {
		const list = document.getElementsByClassName("interactive-detail__item");
		for (const item of list) {
			item.style.cssText = `opacity: ${opacity}; transition: opacity .3s ease;`;
		}
	}, [opacity]);

	return (
		createPortal(
			<div
				key={"detail-map"}
				ref={detailModal}
				className={
					cx(
						"interactive-detail",
						{ "interactive-detail_opened": !closed },
						{ "interactive-detail_closed": closed },
					)
				}
			>
				<div
					className={"container"}
					style={{
						height: "100%",
					}}
				>
					<div
						className={"row"}
						style={{
							height: "100vh",
						}}
					>
						<div
							className={"col-12 order-2 order-md-1 col-md-4 d-flex align-items-end interactive-detail__controls"}
						>
							<CityList items={cityList} callback={selectBuilding} mouseLeave={mouseLeave} openModal={openModal}/>
						</div>
						<div
							className={
								cx(
									"col-12 order-1 order-md-2 col-md-8 d-flex align-items-center interactive-detail__map",
									{ "interactive-detail__map_opened": !closed },
									{ "interactive-detail__map_closed": closed },
								)
							}
						>
							<Element {...data} initial />
						</div>
					</div>
				</div>
				<div className={"interactive-detail__close"} onClick={() => setClosed(true)}>
					<Element {...closeMagnifer} initial />
				</div>
			</div>,
			portal,
		)
	);
}

Detail.propTypes = {
	data: PropTypes.object,
};

Detail.defaultProps = {
	data: {},
};
