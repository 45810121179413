import React, { useEffect, useMemo, useState, useRef } from "react";
import { useToggle, useDebounceFn } from "@umijs/hooks";
import { Button, Popover, Icon as AntIcon } from "antd";
import { get } from "lodash";
import { isMobile } from "react-device-detect";
import panzoom from "@panzoom/panzoom";

import { MapProvider } from "./Context";
import details from "./details.js";
import Element from "./Element";
import Detail from "./Detail";
import Modal from "./Modal";

import data from "./data.js";
import "./InteractiveMap.scss";

export default function InteractiveMap() {
	const map = useRef(null);

	const [detail, setDetail] = useState(false);
	const [modal, setModal] = useState(false);
	const [depth, setDepth] = useState(0);
	const [instance, setInstance] = useState(null);

	const { state: isModalOpen, toggle: setIsModalOpen } = useToggle(false);

	const { run } = useDebounceFn(scale => setDepth(scale), 500);

	const step = isMobile ? 0.5 : 0.05;
	const maxScale = 3;
	const minScale = 0.1;

	function zoomChange(event) {
		const scale = get(event, "detail.scale", 1);

		run(scale.toFixed(2));
	}

	useEffect(() => {
		setInstance(
			panzoom(map.current, {
				maxScale,
				minScale,
				step,
				canvas: true,
			}),
		);
		map.current.addEventListener("panzoomchange", zoomChange);

		return map.current.removeEventListener("panzoomzoom", zoomChange);
	}, []);

	return (
		<div
			id={"map-container"}
			className={"interactive-map"}
		>
			<div className={"mb-3 mb-md-0"} ref={map}>
				<MapProvider
					value={
						{
							setDetail,
							modal,
							setModal,
							isModalOpen,
							setIsModalOpen,
							depth,
						}
					}
				>
					{useMemo(() => <Element {...data} initial />, [depth])}
					{/* TODO: Use multiple details */}
					{detail && <Detail data={details} />}
					{isModalOpen && <Modal data={modal} />}
				</MapProvider>
			</div>
			<div className={"d-flex justify-content-end interactive-map__controls"}>
				<Button.Group value={"small"}>
					<Button
						value="large"
						icon={"plus-circle"}
						onClick={() => {
							if (instance) {
								instance.zoomIn({ animate: true });
							}
						}}
					/>
					<Button
						value="small"
						icon={"minus-circle"}
						onClick={() => {
							instance.zoomOut({ animate: true });
						}}
					/>
					<Button
						value="default"
						icon={"close-circle"}
						onClick={() => {
							if (instance) {
								instance.reset();
							}
						}}
					/>
					<Popover
						placement={"topRight"}
						overlayClassName={"map-dropdown"}
						overlayStyle={
							{
								width: isMobile ? "auto" : 400,
								position: "fixed",
							}
						}
						content={
							<div className={"p-3"}>
								<h5>Элементы управления картой</h5>
								<div><AntIcon type={"plus-circle"} /> Увеличение масштаба</div>
								<div><AntIcon type={"minus-circle"} /> Уменьшение масштаба</div>
								<div className={"mb-3"}><AntIcon type={"close-circle"} /> Сброс масштаба</div>
								<div className={"mb-3"} style={{ textAlign: "justify" }}>
									При нажатии на элемент лупы, откроется дополнительное окно информации.
								</div>
								<div>
									При увеличении масштаба, появятся дополнительные элементы карты.
								</div>
							</div>
						}
						trigger={["click", "hover"]}
					>
						<Button value="small" icon={"question-circle"} />
					</Popover>
				</Button.Group>
			</div>
		</div>
	);
}

