import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import Pages from "../../components/Pages";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import InteractiveMap from "../../components/InteractiveMap";
import seoData from "../../components/SEO/data";
import { useThemeContext } from "../../components/Layout/ThemeContext";
import { removeEmptyKeys, removePreloader } from "../../helpers";
import { Share } from "../../widgets";
import { ContentBlock } from "../../components/Blocks";
export default function MapPage() {
	const url = "map";

	const { theme } = useThemeContext();

	useEffect(() => {
		removePreloader();
	}, []);

	return (
		<Pages url={url} entity={get(seoData, "map_page", {})}>
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs currentLabel={"Карта жизненного пути"} />
				</div>
			</ContentBlock>
			<ContentBlock>
				<div className={"container py-5"}>
					<h1 style={removeEmptyKeys({ color: theme.color.text })}>
						Карта жизненного пути Серафима Саровского
					</h1>
					<InteractiveMap/>
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share url={url} pageTitleShort={"Карта жизненного пути"} />
			</div>
		</Pages>
	);
}

MapPage.propTypes = {
	data: PropTypes.object,
};
